.input_forgot {
  color: aliceblue;
}
label {
  color: aliceblue;
}
.main_forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.Forgot-form-container {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 10px; */
  border-radius: 8px;
  color: aliceblue;
}
